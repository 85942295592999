import React,{Component} from 'react';
import * as actions from '../../redux/actions';
import {connect} from 'react-redux';
import {browserHistory} from 'react-router';
import constants from "../../constants";
import "../../assets/styles/ssoAuth.scss";
import iconSchoolWide from '../../assets/icons/SVG/icon-schoolwide.svg';

class SSOAuthComponent extends Component{
	constructor(){
		super();

		this.state = {
            myError: "",
            showDefault: false,
            showWarning: false,
            showLoader: true,
            errorMsg:"",
        };
	}

	componentDidMount() {
		this.getBearerToken();
		// console.log("classlink sso reloadingg...")
		// const reloadCount = sessionStorage.getItem('reloadCount');
		// console.log(`classlink sso reloadingg count: ${reloadCount}`);
		// if(reloadCount < 2) {
		// sessionStorage.setItem('reloadCount', String(reloadCount + 1));
		// window.location.reload();
		// } else {
		// sessionStorage.removeItem('reloadCount');
		// }
	}

	retrieveCodeFromCBUrl(){
		let queryParam = window.location.search.substring(1);
        let params = queryParam.split("&");
        for (let i=0;i<params.length;i++) {
            let pair = params[i].split("=");
         	if(pair[0] == "code"){
         		return pair[1];
         	}
         }
         return(false);
	}

	getBearerToken = () => {
		let code = this.retrieveCodeFromCBUrl();
		if(code !== false){
			this.props.ssologin(code);	
		}else{
			window.location.href = '/';
		}
		
	}

	componentWillReceiveProps(next){
		if(next.loginStatus.userData.success){
			this.setState({
				showLoader:true,
				showDefault:true,
				showWarning:false,
			});
			localStorage.clear();
            localStorage.user_type = next.loginStatus.userData.user_type;
			localStorage.user_id = next.loginStatus.userData.user_id;
            localStorage.first_time_login = next.loginStatus.userData.first_time_login;
            localStorage.trialPopupDismissed = "false";
            localStorage.is_lite = next.loginStatus.userData.is_lite;

            if(next.loginStatus.userData.district_id !== undefined) {
                localStorage.district_id = next.loginStatus.userData.district_id;
            }

            localStorage.picture_password_user = "false";
			localStorage.login_domain = this.props.domain ? this.props.domain : "false";
			
			//window.location.href = '/';
			if (
				localStorage.user_type === "Student" ||
				next.loginStatus.userData.user_type == "Student"
			  ) {
				console.log("DEFAULT_STUDENT_PATH");
	  
				browserHistory.push('/home');
			  } else if (
				localStorage.user_type === "Print Only" ||
				next.loginStatus.userData.user_type == "Print Only"
			  ) {
				console.log("DEFAULT_PRINT_ONLY_USER_PATH");
	  
				browserHistory.push('/browse/teaching-resources/reading');
			  } else if (
				localStorage.user_type === "Unlimited" ||
				localStorage.user_type === "System Admin"
			  ) {
				console.log('DEFAULT_UNLIMITED_USER_PATH')
				browserHistory.push('/browse/teaching-resources/reading');
			  } else if (
				next.loginStatus.userData.user_type === "Unlimited" ||
				next.loginStatus.userData.user_type === "System Admin"
			  ) {
				console.log('DEFAULT_UNLIMITED_USER_PATH 212');
				browserHistory.push('/browse/teaching-resources/reading');
			  }
		} else {
            this.setState({
				showLoader:false,
				showDefault:false,
				showWarning:true,
				errorMsg:next.loginStatus.userData.msg,
			});
        }
	}

	handleError(myerr) {
        this.setState({
            myError: myerr
        });
	}
	
	goBack(){
		window.location.href = '/';
	}

	render(){
        return(
            <div className="auth-wrapper">
            	<div className="loading-panel">
            		<div className="logo">
            			<img src={iconSchoolWide} alt="Avatar" className="avatar"/>
            		</div>
            		<div className={this.state.showLoader ? "lds-facebook" : "lds-facebook hidden"}><div></div><div></div><div></div></div>
            		<div className={this.state.showDefault ? "loading-text" : "loading-text hidden"}>
            			<h4>PLEASE WAIT...</h4>
						<p>We are authenticating your details. You will be redirected in a few moments.</p>
            		</div>
            		<div className={this.state.showWarning ? "loading-text warning": "loading-text warning hidden"}>
						<p>{this.state.errorMsg}</p>
						<button className="format-button"  onClick={this.goBack}>
                            Go Back 
                        </button>
            		</div>
            		
            	</div>

            </div>
        );
    }
}

const mapStateToProps = ({ Auth, User, Location }) => ({
    email: Auth.email,
    loginStatus : User.LoginStatus,
    pathValue: Location
})

export default connect(mapStateToProps, actions)(SSOAuthComponent)